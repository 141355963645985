<template>
  <div>
    <div class="wrap">
      <div class="top-area">
        <div class="title">Market</div>
      </div>
    </div>
    <div class="winex-wine-list-wrap">
      <div class="filter-wrap pc-filter-wrap">
        <div class="filter-title-wrap">
          <h2 class="title">Filter</h2>
          <div class="filter" @click="resetFilter"></div>
        </div>

          <PcGroupCheckbox
            v-for="item in filterList"
            :key="item.name"
            :list="item.list"
            :title="item.name"
            :is-open="item.isOpen"
            :change-filter="changeFilter"
          />

      </div>
      <div class="wine-list-wrap">

        <div class="flex flex-col gap-[40px] items-end mobile:!hidden">
          <ul class="flex">
            <li class="leading-[40px] px-[12px] text-[16px] cursor-pointer" :style="{color: sortFilter === 1 ? '#A8221A' : '#424242', fontFamily: sortFilter === 1 ? 'Suit-Bold' : 'Suit-Regular'}" @click="changeOrder(1)">최신순</li>
            <li class="leading-[40px] px-[12px] text-[16px] cursor-pointer" :style="{color: sortFilter === 2 ? '#A8221A' : '#424242', fontFamily: sortFilter === 2 ? 'Suit-Bold' : 'Suit-Regular'}" @click="changeOrder(2)">가격 높은 순</li>
            <li class="leading-[40px] px-[12px] text-[16px] cursor-pointer" :style="{color: sortFilter === 3 ? '#A8221A' : '#424242', fontFamily: sortFilter === 3 ? 'Suit-Bold' : 'Suit-Regular'}" @click="changeOrder(3)">가격 낮은 순</li>
          </ul>

          <div class="pc-wine-list w-full">
            <PcWineList :wine-list="viewWineList" :page-info="pageInfo" :move-page="clickPager"/>
          </div>
        </div>



        <!--  mobile-->
        <div class="hidden mobile:!flex flex-col gap-[16px]">
          <div class="flex justify-between">
            <div class="bg-[center_left] pl-[32px] bg-no-repeat bg-[length_24px] text-[16px] !font-[Suit-Bold] text-darkDark leading-[32px]"
                 :style="{backgroundImage: `url('${require('@/assets/images/icon/mobile-filter.png')}')`}"
                 @click="filterModalIsOpen = true"
            >Filter</div>
            <div class="py-[6px] text-[16px] text-[#A8221A] font-[Suit-Bold]" @click="isOpenSortFilter=true">{{ getSort() }}</div>
          </div>
          <div v-if="$route.query.sub_category_no" class="flex flex-col gap-[16px] w-full">
            <div class="w-full h-[1px] bg-light"/>
            <div class="flex gap-[10px] relative">

              <div class="flex w-full overflow-auto gap-[8px] flex-grow pr-[30px]">
                <div
                    v-for="(filter, index) in selectFilterList"
                    :key="filter.name"
                    class="flex w-fit flex-shrink-0 items-center flex-[0 0 auto] border border-lightLightDark rounded-[16px] h-[32px] pl-[16px] pr-[6px]"
                    @click="removeFilter(filter, index)"
                >
                  <p class=" text-[14px] font-[Suit-Medium] text-darkLight m-0 flex-shrink-0">{{filter.mainName}}: {{filter.name}}</p>
                  <img src="@/assets/images/icon/icon-dark-dark-close.png" class="w-[32px] h-[32px] flex-shrink-0" />
                </div>
              </div>
              <div class="cursor-pointer w-[32px] h-[32px] bg-no-repeat bg-center" @click="resetFilter" :style="{backgroundImage: `url('${require('@/assets/images/icon/filter-reload.png')}')`}"/>
              <div class="w-[40px] h-[32px] top-0 bottom-0 bg-gradient-to-r from-[#FFFFFF00] to-white absolute right-[39px]" />

            </div>

          </div>
          <div class="mobile-wine-list">
            <MobileWineList
                :wine-list="viewWineList"
                :next-page="nextPage">

            </MobileWineList>
          </div>
        </div>


        <FilterModal
            v-if="filterModalIsOpen"
            :originFilterList="filterList"
            :close-filter-modal="mobileFilterChange"
            @close="filterModalIsOpen=false"
            @change="mobileFilterChange"
        />




<!--        <div class="mobile-list-wrap">
          <div class="wine-list-mobile-header">
            &lt;!&ndash; TODO :: 잠시 예외 처리 &ndash;&gt;
            <div class="filter-wrap" @click="openFilterModal">
              <div class="filter-icon"></div>
              <div>Filter</div>
            </div>
            <div></div>
          </div>
          <MobileWineList :wine-list="viewWineList" :next-page="nextPage"
          :no-result-text="noResultText">
          </MobileWineList>
          <FilterModal
              v-if="filterModalIsOpen"
            :filterList="filterList"
            :close-filter-modal="changeFilter"
          />
        </div>-->
      </div>
    </div>
    <NftSortModal :order="sortFilter" v-if="isOpenSortFilter" @change="changeOrder" />

  </div>
</template>
<script>
import PcWineList from '../../components/list/PcWineList';
import MobileWineList from '../../components/list/MobileWineList';
import getContractApi from '../../api/contract';
import PcGroupCheckbox from '../../components/checkbox/pcGroupCheckbox';
import FilterModal from '../../components/modals/FilterModal.vue';
import nftList from "@/mxins/nftList";
import NftSortModal from "@/components/modals/NftSortModal";

export default {
  mixins: [nftList],
  components: {NftSortModal, MobileWineList, PcWineList, PcGroupCheckbox, FilterModal },
  created() {
    this.market = getContractApi();
    if(this.$route.query.order) {
      this.nftOrder = this.$route.query.order
    }
  },
  mounted() {

    
    const _this = this;
    this.market.getWineMarket().getUserAddress().then(data => {
      if(data){
        _this.$store.state.user.walletAddress = data;
      }
    })
    

    this.$store.dispatch('wineFilterList/getWineFilterList', {})
            .then((data) => {
              this.filterList = data;
              if(this.$route.query.sub_category_no) {
                let subCategoryList = this.$route.query.sub_category_no.split(',')
                this.filterList.forEach(main => {
                  main.list.forEach(sub => {
                    if(subCategoryList.find(item => item === sub.key.toString())) {
                      sub.select = true
                      main.isOpen = true
                      sub.mainName = main.name;
                      this.selectFilterList.push(sub);
                    }

                  })
                })
              }
            });


    this.searchData = this.$route.query
    if(this.searchData.page) {
      this.getWineList(this.searchData.page)
    } else {
      this.getWineList(1)
    }
  },
  data() {
    return {
      noResultText:"NFT 리스트를 로드 중입니다. 잠시만 기다려 주십시오.",
      nftOrder:"1",
      contract: null,
      totalSupply: 0,
      viewWineList: [],
      filterList: [],
      filterModalIsOpen: false,
      filterSelected:'',
      pageInfo: {
        current_page: 1,
        total_page: 1,
        item_per_page: 1,
      },

      sortFilter: this.$route.query.order ? Number(this.$route.query.order) :  1,
      searchData: {

      },


      selectFilterList: [],
    };
  },

  methods: {
    mobileChangeFilter(data){
      this.filterList = data.filter;
      this.changeFilter()
    },
    changeFilter() {

      this.filterModalIsOpen = false;
      this.filterSelected = ''
      this.filterList.forEach(item => {
        item.list.forEach(listItem => {
          if(listItem.select) {
            this.filterSelected = this.filterSelected + listItem.key + ','
          }
        })
      })



      this.searchData.sub_category_no = this.filterSelected


      this.getWineList(1,'pass')
    },

    getWineFilterList() {
      this.$store
        .dispatch('wineFilterList/getWineFilterList', {})
        .then((data) => {

          this.filterList = data.map((item) => {
            const changeValue = {};

            changeValue.name = item.filter_category_name;
            changeValue.isOpen = item.isOpen;
            changeValue.list = item.subCategories.map((subItem) => ({
              key: subItem.filter_sub_category_no,
              name: subItem.filter_sub_category_name,
              select: subItem.select,
            }));

            return changeValue;
          })


          // this.filterList = data;
          this.changeFilter()
        });
    },
    clickPager(page) {
      this.getWineList(page,'pass')
    },
    openFilterModal() {
      this.filterModalIsOpen = true;
    },
    getWineList(page,mode, state) {
      // 통신 처리
      let self = this
      if(mode !== 'mobile') {
        if (page !== this.pageInfo.current_page) {
          // window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }

      this.searchData.order = this.nftOrder
      this.searchData.page = page
      this.searchData.market_list = 1

      if(mode === 'pass') {

        self.$router.push({
          path: this.$route.path,
          query: {
            'sub_category_no': this.getFilterSelect(),
            'page': page,
            'order': this.sortFilter,
            'keyword': this.$route.query.keyword || ''
          }
        }).catch((error) => {
          console.dir(error)
          // self.$router.go(0)
        });

        // location.href='/market/list?' + new URLSearchParams(this.searchData).toString()
      }

      this.$store.dispatch('wineMarket/getWineMarketList',this.searchData).then((data) => {
        // 데이터 재가공
        if(data.total === 0) {
          self.noResultText = "검색 결과가 없습니다."
        }
        let searchedWineList = data.data
        for(let i = 0; i < searchedWineList.length; i++) {
          searchedWineList[i].name = searchedWineList[i].wine_name
          searchedWineList[i]['price'] = {
            coin: searchedWineList[i].last_price / 1000000,
            won: self.market.toWon(searchedWineList[i].last_price / 1000000)
          }
          searchedWineList[i].key = searchedWineList[i].market_key+"/"+searchedWineList[i].nft_key
          // searchedWineList[i]['price']['won'] = 10
          searchedWineList[i].image = searchedWineList[i].wine_image
        }

        if(mode === 'mobile') {
          self.viewWineList.push(...searchedWineList)
          state.loaded();
        } else {
          self.viewWineList = searchedWineList
        }


        self.pageInfo = {
          current_page: data.current_page,
          total_page: data.last_page,
          item_per_page: data.per_page,
        }

      })
      this.pageInfo.current_page = page;
    },
    getFilterSelect(){
      const select = [];

      this.filterList.forEach(item => {
        item.list.forEach(listItem => {
          if(listItem.select) {
            select.push(listItem.key);
          }
        })
      })

      return select.join(',')
    },
    nextPage($state) {
      if (this.pageInfo.current_page < this.pageInfo.total_page) {
        this.getWineList(this.pageInfo.current_page + 1, 'mobile', $state)
        // $state.loaded(); // 다음 페이지가 있을 경우
      } else {
        $state.complete(); // 다음 페이지가 없을 경우
        // alert('마지막 페이지 입니다.');
      }
    },
    removeFilter(item, index){
      if(item.mainName === 'Status'){
        this.filterList.forEach((filter) => {
          filter.list[0].select = false;
        })
      }else{
        this.filterList.forEach((filter) => {
          if(filter.name == item.mainName){
            filter.list.forEach((i) => {
              if(i.key === item.key){
                i.select = false;
              }
            })
          }
        })
      }


      this.selectFilterList.splice(index, 1);

      this.getWineList(1, 'pass');
    },

    mobileFilterChange(data){
      this.filterList = data.filter;

      this.filterModalIsOpen = false;
      this.getWineList(1,'pass')
    },

  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/nft-list.scss';
</style>
